import { Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { useCustomer } from '../../hooks/use-customer';
import notification from '../utility/notification';

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

const EditAccountModal = ({
  open = false,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const {
    customer,
    actions: { updateCustomerDetails },
  } = useCustomer();
  const [form] = Form.useForm<FormValue>();

  useEffect(() => {
    form.resetFields();
  }, [customer]);

  const onSubmit = async (formValues: FormValue) => {
    setLoading(true);
    try {
      await updateCustomerDetails(formValues);

      setOpen(false);

      notification({
        key: 'update-account-success',
        type: 'success',
        message: 'แก้ไขข้อมูลส่วนตัวของคุณสำเร็จ',
      });
    } catch (err) {
      if ((err as { type?: string }).type === 'duplicate_error') {
        notification({
          key: 'update-account-error',
          type: 'error',
          message: 'อีเมลมีอยู่ในระบบแล้ว กรุณาติดต่อแอดมิน',
        });
      } else {
        notification({
          key: 'update-account-error',
          type: 'error',
          message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      title="แก้ไขข้อมูล"
      okText="บันทึก"
      cancelText="ยกเลิก"
      onOk={() => form.submit()}
      confirmLoading={loading}
      width={600}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
        disabled={loading}
      >
        <div className="flex flex-col gap-x-4 md:flex-row">
          <Form.Item
            name="first_name"
            label="ชื่อ"
            className="md:basis-1/2"
            initialValue={customer?.first_name}
            required={false}
            rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="last_name"
            label="นามสกุล"
            className="md:basis-1/2"
            initialValue={customer?.last_name}
            required={false}
            rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          label="อีเมล"
          initialValue={customer?.email}
          required={false}
          rules={[
            { required: true, message: 'กรุณากรอกอีเมล' },
            { type: 'email', message: 'กรุณากรอกอีเมลให้ถูกต้อง' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          initialValue={customer?.phone}
          label="เบอร์ติดต่อ"
          required={false}
          rules={[{ required: true, message: 'กรุณากรอกเบอร์ติดต่อ' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAccountModal;
